

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500');

h1 {text-transform: uppercase; }
h2 {text-transform: uppercase; color:#96c63e; }

.homepage-hero {margin-bottom:2rem;}

.content {margin-top: 1.5em;}


.title {text-align: center; padding-bottom: 0.5em;

}
.title:after {
    content: " ~";
}

.title:before {
    content: "~ ";
}

$nav-text-color: $white;
$nav-height: 75px;
$nav-background: #2c3840;
$offcanvas-padding: 20px;

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  height: $nav-height;
  margin-bottom: 5px;


  .nav-bar-logo {
    padding: 1.8rem;
  }

  li {
     background: $white;
  }

  a {
    color: $light-gray;
    transition: color 0.2s ease-in;

    &:hover {
      color: $primary-color;
    }
  }

  @media screen and (max-width: 39.9375em) {
    height: $nav-height/1.4;
  }

  .offcanvas-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem;
    color: $nav-text-color;
    text-transform: uppercase;
    background-color: lighten($primary-color, 20%);
    transition: background-color 0.2s ease-in;
    cursor: pointer;

    .offcanvas-trigger-text {
      margin-right: 0.25rem;
      margin-top: 0.25rem;
    }

    &:hover {
      background-color: lighten($primary-color, 30%);
    }

    @media screen and (max-width: 39.9375em) {
      padding: 0.9rem;
    }
  }
}

.multilevel-offcanvas {
  background: darken($nav-background, 5%);
  padding: 20px;
  color: $white;

  > .menu {
    margin-bottom: 1rem;
  }

  .menu a {
    color: rgba($nav-text-color,.6);
    padding-top: 7px;
    padding-bottom: 7px;
    transition: color 0.2s ease-in;

    &:hover {
      color: $nav-text-color;
    }
  }

  .fa {
    color: $nav-text-color;
  }

  .is-accordion-submenu-parent > a::after {
    border-color: rgba($nav-text-color,.6) transparent transparent;
  }

  .social-links {
    margin-left: $offcanvas-padding;

    li {
      margin-right: 5px;
    }

    a:hover .fa {
      color: darken($nav-text-color,25%);
      transition: color .2s ease-in;
    }

    .fa {
      font-size: 2.2rem;
    }
  }
}

.body-info {
  text-align: center;
  color: $nav-background;

  .button {
    margin: 1rem 0;
    padding: 0.85em 2em;
  }
}

.is-open + .off-canvas-content .hamburger {
  .line:nth-child(1),
  .line:nth-child(3) {
    width: 12px;
  }

  .line:nth-child(1) {
    transform: translateX(-5px) rotate(-45deg);
  }

  .line:nth-child(3) {
    transform: translateX(-5px) rotate(45deg);
  }
}

.hamburger .line {
  width: 20px;
  height: 2px;
  background-color: $nav-text-color;
  display: block;
  margin: 3px auto;
  transition: all 0.3s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }
}


/* Homepage Table */
$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 1px solid $light-gray;
$responsive-card-table-stack-point: 640px;
$responsive-card-table-label-bg: $primary-color;

.responsive-card-table {
  border-collapse: collapse;

  &.unstriped tbody {
    background-color: transparent;
  }

  th {
    background-color: $responsive-card-table-label-bg;
  }

  tr,
  th,
  td {
    border: $responsive-card-table-border;
  }

  @media screen and (max-width: $responsive-card-table-stack-point) {
    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: $responsive-card-table-border;
      display: block;
    }

    tr + tr {
      margin-top: $responsive-card-table-bottom-gap;
    }
    /* Get table cells to act like rows */

    td {
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: $responsive-card-table-border;
      padding-left: 50%;
      position: relative;


      &:before {
        content: attr(data-label);
        display: inline-block;
        font-weight: 300;
        line-height: 1.5;
        margin-left: -100%;
        width: 100%;
        position: relative;
        z-index: 1;
        color:$white;
      }

      &:after {
        content: '';
        position: absolute;
        background: $responsive-card-table-label-bg;
        width: 45%;
        height: 95%;
        left: 1px;
        top: 1px;
        z-index: 0;
      }
    }
  }
}




/* Homepage slider   */


$clean-hero-slider-accent: $primary-color;

.clean-hero-slider {
  .orbit-caption {
    width: auto;
    height: auto;
    background: $black;
    position: absolute;
    text-align: center;
    right: 0;
    left: 0;
    padding: 0.2rem 2rem;

    @include breakpoint(medium) {
      @include vertical-center;
      background: rgba(0,0,0,.6);
      text-align: left;
      width: 70%;
      right: 0;
      padding: 1rem 2rem;
    }

    @include breakpoint(xlarge) {
      padding-right: 10%;
    }

    @include breakpoint(xxlarge) {
      padding-right: 20%;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.25;

      @include breakpoint(medium) {
        font-size: 1.1rem;
      }
    }

    h3 {
      color: $clean-hero-slider-accent;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0;

      @include breakpoint(medium) {
        font-size: 2.5rem;
        font-weight: normal;
      }
    }
  }

  .orbit-slide {
    padding-bottom: 40%;
    background: $black;

    @include breakpoint(medium) {
      padding-bottom: 0;
    }
  }

  .orbit-controls {
    button {
      background: $clean-hero-slider-accent;
      padding: 1rem 0.5rem;

      &:hover {
        background: $white;
        color: $black;
      }
    }

    .orbit-previous {
      @include breakpoint(medium) {
        left: auto;
        right: 2.4rem;
      }
    }

    .orbit-next {
      @include breakpoint(medium) {
        right: 0.5rem;
      }
    }
  }

  .orbit-bullets {
    display: none;

    @include breakpoint(medium) {
      display: block;
      margin-bottom: 0;
      margin-top: -3rem;
      background: none;
    }

    button {
      width: 3rem;
      height: 0.4rem;
      border-radius: 0;
      background-color: $white;

      &.is-active {
        background-color: $clean-hero-slider-accent;
      }
    }
  }

  ul.orbit-container {
    height: auto !important;
  }

  li.orbit-slide {
    max-height: none !important;
  }
}


/* Homepage welcome block */

.homepage-welcome {margin:1rem;}

/* Team cards */

.card-profile-stats {
  border: 1px solid $medium-gray;
  margin: 0 0 1.25rem;
  padding: 1.25rem;
  background: $white;

  .card-profile-stats-intro {
    text-align: center;

    .card-profile-stats-intro-pic {
      border-radius: 50%;
      margin: 0 0 1.25rem;
    } // .card-profile-stats-intro-pic

    .card-profile-stats-intro-content {
      p:last-child {
        margin: 0;
      } // p:last-child
    } // .card-profile-stats-intro-content
  } // .card-profile-stats-intro

  .card-profile-stats-container {
    text-align: center;

    .card-profile-stats-statistic {
      margin: 0 0 1.25rem;

      p:last-child {
        margin: 0;
      }
    } // .card-profile-stats-statistic
  } // .card-profile-stats-container

  .card-profile-stats-more {
    .card-profile-stats-more-link {
      margin: 0;
      text-align: center;
      font-size: 1.5rem;
      color: $body-font-color;
      padding: 0.5rem;
      cursor: pointer;
    } // .card-profile-stats-more-link

    .card-profile-stats-more-content {
      display: none;
    } // .card-profile-stats-more-content
  } // .card-profile-stats-more
} // .card-profile-stats



/* Large and up */
@include breakpoint(large) {
  .card-profile-stats {
    .card-profile-stats-intro {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      text-align: left;

      .card-profile-stats-intro-pic {
        margin: 0 1.25rem 0 0;
        max-width: 250px;
      } // .card-profile-stats-intro-pic
    } // .card-profile-stats-intro

    .card-profile-stats-container {
      display: flex;
      justify-content: space-around;
    } // .card-profile-stats-container
  } // .card-profile-stats

} // media query - large & up





$quote-inline-width: rem-calc(350);

.quote-inline-wrap-article-content {
  max-width: 100%;
  position: relative;
}

.quote-inline-wrap {
  float: left;
  max-width: $quote-inline-width;
  padding: 0 1rem 1rem 0;

  .quote-inline-marks {
    font-size: rem-calc(100);
    color: $dark-gray;
    line-height: 1rem;
  }

  .quote-inline-testimonial {
    border-radius: 5px;

    p {
      font-weight: 300;
    }
  }

  .quote-inline-person {
    margin-bottom: 1.5rem;

    .quote-inline-photo img {
      border-radius: 50%;
      width: rem-calc(70);
      height: rem-calc(70);
      float: left;
      margin-right: 1rem;
    }

    p {
      position: relative;
      top: 5px;

      &:nth-child(2) {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
      }

      &:nth-child(3) {
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark-gray;
      }
    }
  }

  @include breakpoint(medium down) {
    width: 100%;
    float: none;
    display: block;
  }
}
